<template>
  <div>
    <div class="d-flex flex-wrap" id="application-action-buttons">
      <AdsButton
        class="mr-3 withdraw-btn"
        secondary
        @click="openDialog(statusLabel.WITHDRAWN.value)"
        data-testid="withdrawn-btn"
        :disabled="isEditing"
        outlined
        icon="mdi-delete-outline"
        button-text="Withdraw"
      />
      <AdsButton
        class="mr-3 warning-btn"
        color="red"
        primary
        @click="openDialog(statusLabel.DECLINED.value)"
        data-testid="declined-btn"
        :disabled="isEditing"
        icon="mdi-close-circle-outline"
        button-text="Decline"
        outlined
      />
      <AdsButton
        class="primary-btn"
        @click="openDialog(statusLabel.OFFERED.value)"
        data-testid="offered-btn"
        :disabled="isEditing"
        icon="mdi-school-outline"
        button-text="Offer"
      />
    </div>
    <v-dialog
      v-model="showConfirmationModal"
      max-width="630px"
      ref="AppModal"
      data-testid="confirmation-modal"
      persistent
      class="ooa-modal"
    >
      <v-form ref="ooaForm" v-model="ooaStatusChangeFormValid">
        <v-card class="modal">
          <div class="header">
            <v-btn
              ref="closeButton"
              class="close-button"
              icon
              aria-label="close"
              @click="closeDialog"
            >
              <v-icon> close </v-icon>
            </v-btn>
            <div
              class="pa-1"
              v-if="applicationStatus === statusLabel.OFFERED.value"
            >
              <h2 class="navyBlueText boldText mb-0">
                Make an offer of enrolment to
                {{ getValue('student.firstName') }}
                {{ getValue('student.otherName') }}
                {{ getValue('student.familyName') }}?
              </h2>
            </div>
            <div
              class="pa-1"
              v-if="applicationStatus === statusLabel.DECLINED.value"
            >
              <h2 class="boldText mb-0">
                <span class="redText">Decline</span> out-of-area application for
                {{ getValue('student.firstName') }}
                {{ getValue('student.otherName') }}
                {{ getValue('student.familyName') }}?
              </h2>
            </div>
            <div
              class="pa-1"
              v-if="applicationStatus === statusLabel.WITHDRAWN.value"
            >
              <h2 class="navyBlueText boldText mb-0">
                <span class="redText">Withdraw</span> out-of-area application
                for
                {{ getValue('student.firstName') }}
                {{ getValue('student.otherName') }}
                {{ getValue('student.familyName') }}?
              </h2>
            </div>
          </div>
          <div class="pa-1">
            <div v-if="applicationStatus === statusLabel.OFFERED.value">
              <div>
                The status of the application will be set to
                <strong>"Offered"</strong>.
              </div>
              <div class="approvalAlert pt-2" v-if="isOverCap">
                <v-icon size="22" class="pr-3 alertIcon"
                  >mdi-alert-outline</v-icon
                >
                <strong>Note: </strong>DEL approval is required before offering
                a place as your school is currently over cap.
              </div>
              <div class="email-checkbox-wrapper pr-4 pl-4 pt-1 pb-6 mt-6">
                <OesCheckbox
                  v-model="offeredEmailCheckbox"
                  color="black"
                  class="pb-2"
                  :aria-label="offeredEmailLabelMessage"
                >
                  <template #label>
                    <div
                      class="email-checkbox-label"
                      :aria-label="offeredEmailLabelMessage"
                    >
                      Email parent/carer
                      <strong>{{ parentCarerDisplayName }}</strong> instructions
                      for accepting the offer and proceeding with a full
                      enrolment application.
                    </div>
                  </template>
                </OesCheckbox>

                <strong class="email-template-uri email-checkbox-sibling"
                  ><a :href="emailTemplateURI" target="_blank"
                    >View the email template</a
                  ></strong
                >
              </div>
            </div>
            <div v-if="applicationStatus === statusLabel.DECLINED.value">
              <div>
                The status of the application will be set to
                <strong>"Declined"</strong>.
              </div>

              <div
                class="email-checkbox-wrapper email-checkbox-declined pr-4 pl-4 pt-1 pb-6 mt-6"
              >
                <OesCheckbox
                  v-model="declineEmailCheckbox"
                  color="black"
                  class="pb-2"
                  :aria-label="declineEmailLabelMessage"
                >
                  <template #label>
                    <div
                      class="email-checkbox-label"
                      :aria-label="declineEmailLabelMessage"
                    >
                      Email parent/carer
                      <strong>{{ parentCarerDisplayName }}</strong> to advise
                      the application has been declined.
                    </div>
                  </template>
                </OesCheckbox>

                <strong class="email-template-uri email-checkbox-sibling"
                  ><a :href="emailTemplateURI" target="_blank"
                    >View the email template</a
                  ></strong
                >
              </div>
            </div>
            <div v-if="applicationStatus === statusLabel.WITHDRAWN.value">
              <div class="pr-4 pl-0 pt-1 pb-6">
                <p>
                  The status of this application will be set to
                  <strong>"Withdrawn"</strong>.
                </p>
                <p>
                  Please ensure you have spoken with the parent/carer(s) before
                  withdrawing.
                </p>
                <p>
                  Parent/carer
                  <strong>{{ parentCarerDisplayName }}</strong> will be emailed
                  with a confirmation that their application has been withdrawn.
                </p>

                <strong class="email-template-uri"
                  ><a :href="emailTemplateURI" target="_blank"
                    >View the email template</a
                  ></strong
                >
              </div>
            </div>

            <v-divider class="mt-5 mb-7" />

            <div
              data-testid="capture-criteria"
              v-if="applicationStatus === statusLabel.OFFERED.value"
            >
              <p class="my-7">
                Which criteria supported you to make your decision about this
                out-of-area application?
              </p>

              <v-select
                v-model="considerations.primary"
                :items="primaryCriteriaList"
                item-text="value"
                item-value="code"
                label="Primary criteria *"
                placeholder="Select criteria"
                :outlined="true"
                class="mt-3"
                persistent-placeholder
                :rules="[(val) => !!val || 'Required.']"
                max-width="556px"
                hint="Required."
                persistent-hint
                :menu-props="{
                  contentClass: 'v-select--wrap-list-items'
                }"
              >
                <template #item="data">
                  <v-list-item-title
                    :class="`${data.item.isBold ? 'font-weight-bold' : ''}`"
                  >
                    {{ data.item.value }}
                  </v-list-item-title>
                </template>
              </v-select>

              <v-select
                v-model="considerations.secondary"
                :items="secondaryCriteriaList"
                item-text="value"
                item-value="code"
                label="Secondary criteria"
                placeholder="Select criteria"
                outlined
                class="mt-3"
                persistent-placeholder
                :disabled="!considerations.primary"
                hint="Select a primary criteria first."
                persistent-hint
                :menu-props="{
                  contentClass: 'v-select--wrap-list-items'
                }"
              >
                <template #item="data">
                  <v-list-item-title
                    :class="`${data.item.isBold ? 'font-weight-bold' : ''}`"
                  >
                    {{ data.item.value }}
                  </v-list-item-title>
                </template>
              </v-select>

              <v-select
                v-model="considerations.tertiary"
                :items="tertiaryCriteriaList"
                item-text="value"
                item-value="code"
                label="Tertiary criteria"
                placeholder="Select criteria"
                :outlined="true"
                class="mt-3"
                persistent-placeholder
                :disabled="!considerations.secondary"
                hint="Select a secondary criteria first."
                persistent-hint
                :menu-props="{
                  contentClass: 'v-select--wrap-list-items'
                }"
              >
                <template #item="data">
                  <v-list-item-title
                    :class="`${data.item.isBold ? 'font-weight-bold' : ''}`"
                  >
                    {{ data.item.value }}
                  </v-list-item-title>
                </template>
              </v-select>
            </div>

            <div
              data-testid="capture-reason"
              v-if="applicationStatus === statusLabel.DECLINED.value"
            >
              <p class="my-7">Why was this out-of-area application declined?</p>

              <v-select
                v-model="declinedReason"
                :items="activeDeclinedReasons"
                item-text="value"
                item-value="code"
                label="Reason *"
                placeholder="Select reason"
                :outlined="true"
                class="mt-3"
                persistent-placeholder
                :rules="[(val) => !!val || 'Required.']"
                max-width="556px"
                hint="Required."
                persistent-hint
                :menu-props="{
                  contentClass: 'v-select--wrap-list-items'
                }"
              />
            </div>

            <AdsTextArea
              v-model="comment"
              class="mt-3"
              :label="`Comments (school use only${
                hasSelectedOtherConsiderations ? ') *' : ' - optional)'
              }`"
              placeholder=" "
              rows="5"
              :counter="counter"
              :maxlength="counter"
              data-testid="application-comments"
              persistent-placeholder
              :rules="
                hasSelectedOtherConsiderations
                  ? [(val) => !!val || 'Required.']
                  : []
              "
            />

            <SupportingDocumentUpload
              v-model="files"
              ref="supportingDocumentUpload"
              :is-busy.sync="isFileOperationInProgress"
            />
          </div>
          <div class="confirmation-modal-btns d-flex justify-end mt-3">
            <AdsButton
              v-bind="confirmButtonProps"
              @click="updateStatus"
              :disabled="isDisabled"
              height="48px"
              min-width="50%"
              data-testid="confirmButton"
            />
          </div>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import OesCheckbox from '@/components/app/OesCheckbox'
import { AdsButton, AdsTextArea } from '@nswdoe/doe-ui-core'
import SupportingDocumentUpload from '@/components/app/SupportingDocumentUpload'
import { OOA_STATUS } from '@/constants'
import settingsUtils from '@/helpers/settingsUtils'
import { mapGetters } from 'vuex'

const STATUS_LABEL = {
  OFFERED: {
    value: OOA_STATUS.OFFERED,
    btnLabel: 'Approve'
  },
  DECLINED: {
    value: OOA_STATUS.DECLINED,
    btnLabel: 'Decline'
  },
  WITHDRAWN: {
    value: OOA_STATUS.WITHDRAWN,
    btnLabel: 'Withdraw'
  }
}
export default {
  name: 'ApplicationStatusButtons',
  mixins: [fieldHelperMixin],
  components: {
    AdsButton,
    AdsTextArea,
    OesCheckbox,
    SupportingDocumentUpload
  },
  data() {
    return {
      applicationStatus: null,
      showConfirmationModal: false,
      statusLabel: STATUS_LABEL,
      offeredEmailCheckbox: true,
      comment: '',
      declineEmailCheckbox: false,
      counter: 200,
      files: [],
      isFileOperationInProgress: false,
      considerations: {
        primary: null,
        secondary: null,
        tertiary: null
      },
      declinedReason: null,
      ooaStatusChangeFormValid: true
    }
  },
  computed: {
    ...mapGetters(['schoolOoaSettings', 'activeDeclinedReasons']),
    parentCarerDisplayName() {
      const selectedPC = this.getValue('parentCarers').filter(
        (p) => p.parentCarerCompletingApp
      )
      return `${selectedPC[0].parentCarerGivenName} ${selectedPC[0].parentCarerFamilyName}`
    },
    emailTemplateURI() {
      return this.$store.getters.emailTemplateLink
    },
    declineEmailLabelMessage() {
      return `Email parent/carer ${this.parentCarerDisplayName} to advise the application has been declined`
    },
    offeredEmailLabelMessage() {
      return `Email parent/carer ${this.parentCarerDisplayName} instructions for accepting the offer and proceeding with a full enrolment application`
    },
    isDisabled() {
      return (
        this.comment.length > this.counter ||
        this.isFileOperationInProgress ||
        !this.ooaStatusChangeFormValid
      )
    },
    confirmButtonProps() {
      const props = {
        [OOA_STATUS.OFFERED]: {
          color: 'success',
          'button-text': 'Make offer of enrolment',
          class: 'btn-success'
        },
        [OOA_STATUS.DECLINED]: {
          color: 'red',
          'button-text': 'Confirm decline of enrolment',
          class: 'btn-red'
        },
        [OOA_STATUS.WITHDRAWN]: {
          color: 'red',
          'button-text': 'Confirm application withdrawal',
          class: 'btn-red'
        }
      }
      return props[this.applicationStatus] || {}
    },
    schoolSettings() {
      return this.schoolOoaSettings(this.$store.state.application.schoolCode)
    },
    isOverCap() {
      return settingsUtils.isOverCap(this.schoolSettings?.capacity)
    },
    otherConsiderations() {
      return this.schoolSettings?.enableOtherCriteria
    },
    hasSelectedOtherConsiderations() {
      return Object.values(this.considerations).includes('OTH')
    },
    parentSelectedCriteria() {
      const applicationCriteria =
        this.application?.criteria.map((c) => c.code) || []
      // Mapping MED to LAW and siblings to SIB start
      if (this.application?.siblings?.length > 0) {
        applicationCriteria.push('SIB')
      }
      const parentSelectedCriteria = applicationCriteria?.map((code) =>
        code == 'MED' ? 'LAW' : code
      )
      // Mapping MED to LAW and siblings to SIB end
      return parentSelectedCriteria
    },
    schoolCriteriaList() {
      const schoolCriteria = this.schoolSettings?.criteria.map((c) => ({
        code: c.code,
        value: c.value,
        disabled: false
      }))
      const hasOtherConsiderations = !!this.application?.otherConsiderations
      const criteriaList = []
      criteriaList.push({
        code: 'CSBPC',
        header: 'Criteria selected by the parent/carer'
      })
      if (this.parentSelectedCriteria.length > 0) {
        criteriaList.push(
          ...schoolCriteria
            .filter((d) => this.parentSelectedCriteria.includes(d.code))
            .map((finalResult) => {
              return {
                ...finalResult,
                isBold: true
              }
            })
        )
      }
      if (hasOtherConsiderations && this.otherConsiderations) {
        criteriaList.push({
          code: 'OTH',
          value: 'Other considerations',
          disabled: false,
          isBold: true
        })
      }
      if (
        this.parentSelectedCriteria.length === 0 &&
        !(hasOtherConsiderations && this.otherConsiderations)
      ) {
        criteriaList.push({
          code: 'NONE',
          value: 'None',
          disabled: true
        })
      }
      criteriaList.push({
        code: 'AOC',
        header: 'All other criteria'
      })
      criteriaList.push(
        ...schoolCriteria.filter(
          (d) => !this.parentSelectedCriteria.includes(d.code)
        )
      )
      criteriaList.push({
        code: 'SBC',
        value: 'School is under buffer or cap and has capacity',
        disabled: false
      })
      if (!hasOtherConsiderations && this.otherConsiderations) {
        criteriaList.push({
          code: 'OTH',
          value: 'Other considerations',
          disabled: false
        })
      }
      return criteriaList
    },
    primaryCriteriaList() {
      return this.schoolCriteriaList.map((c) => ({
        ...c,
        disabled:
          c.disabled ||
          c.code === this.considerations.secondary ||
          c.code === this.considerations.tertiary
      }))
    },
    secondaryCriteriaList() {
      return this.schoolCriteriaList.map((c) => ({
        ...c,
        disabled:
          c.disabled ||
          c.code === this.considerations.primary ||
          c.code === this.considerations.tertiary
      }))
    },
    tertiaryCriteriaList() {
      return this.schoolCriteriaList.map((c) => ({
        ...c,
        disabled:
          c.disabled ||
          c.code === this.considerations.primary ||
          c.code === this.considerations.secondary
      }))
    }
  },
  methods: {
    openDialog(val) {
      this.showConfirmationModal = true
      this.applicationStatus = val
    },
    updateStatus() {
      this.showConfirmationModal = false
      const params = {
        status: this.applicationStatus,
        comment: this.comment,
        documents: this.files
      }
      if (this.applicationStatus === OOA_STATUS.OFFERED) {
        params.sendEmail = this.offeredEmailCheckbox
        params.considerations = this.considerations
      } else if (this.applicationStatus === OOA_STATUS.DECLINED) {
        params.sendEmail = this.declineEmailCheckbox
        params.declinedReason = this.declinedReason
      } else {
        params.sendEmail = true
      }
      this.$emit('confirmStatusChange', params)
    },
    closeDialog() {
      if (this.files) {
        this.$refs.supportingDocumentUpload.deleteAll()
      }
      this.showConfirmationModal = false
      this.considerations = {}
      this.comment = ''
      this.declinedReason = null
      this.offeredEmailCheckbox = true
      this.declineEmailCheckbox = false
      this.files = []
    }
  },
  watch: {
    showConfirmationModal: function (value) {
      if (value) {
        setTimeout(() => {
          if (this.$refs.closeButton) {
            this.$refs.closeButton.$el.focus()
          }
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
#app.v-application button.v-btn:not(.v-btn--round) {
  &.warning-btn {
    color: #ffffff;
    &:focus {
      box-shadow: 0 0 0 4px $color-red-border;
    }
  }

  &.primary {
    &:focus {
      box-shadow: 0 0 0 4px $color-focus;
    }
  }
}

.modal {
  padding: 2rem;
  .header {
    .close-button {
      position: absolute;
      top: 0.15rem;
      right: 0.15rem;
      border: none;
      &:focus {
        border: 2px solid $color-focus;
      }
    }
  }
  .confirmation-modal-btns .v-btn {
    color: #ffffff;
    &.btn-red:focus {
      box-shadow: 0 0 0 4px $color-red-border !important;
    }
    &.btn-success:focus {
      box-shadow: 0 0 0 4px #ccefce !important;
    }
  }
  .v-text-field.v-textarea {
    margin-left: 0;
  }
}

.redText {
  color: #bb1237;
}
.navyBlueText {
  color: $color-primary;
}
.boldText {
  font-weight: 700;
}

::v-deep .email-checkbox-wrapper {
  background-color: #f4f4f7;
  .v-input__slot {
    align-items: start;
  }
}
.email-checkbox-label {
  color: #121212;
}
.email-template-uri > a {
  color: #121212;
}
.email-template-uri.email-checkbox-sibling {
  margin-left: 33px;
}
::v-deep #application-action-buttons {
  .v-btn {
    width: 150px;
  }
  button[disabled] {
    opacity: 1;
    &.v-btn--outlined {
      color: $color-btn-disabled-text-darker;
      .v-btn__content,
      .v-icon::before {
        color: $color-btn-disabled-text-darker;
      }
    }
    &:not(.v-btn--outlined) {
      background-color: $color-btn-disabled-bg-default !important;
      .v-btn__content,
      .v-icon::before {
        color: $color-btn-disabled-text-default;
      }
    }
  }
}
.approvalAlert {
  line-height: 24px;
  .alertIcon {
    color: $color-warning-dark;
  }
}
::v-deep {
  .v-select:not(.ads-select) {
    & .v-input__control input[readonly] {
      position: unset !important;
      top: unset !important;
    }
  }
}
::v-deep .theme--light.v-input--is-disabled input::placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
}
</style>
<style type="text/css">
.v-select--wrap-list-items {
  max-height: none !important;
  .v-list-item__title {
    white-space: normal;
    max-width: 525px;
    margin: 16px 0;
  }
  .v-list-item__content {
    padding: 0;
  }
}
</style>
